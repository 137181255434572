<template>
  <header class="b-header">
    <div class="b-header__logo">
      <router-link class="b-aclogo" to="/">
        <div class="b-aclogo__icon">
          <svg class="icon-svg">
            <use xlink:href="#svg-logo_header"></use>
          </svg>
        </div>
        <div class="b-aclogo__text">
          <div class="b-aclogo__title">
                <span style="color: #333">Тех</span
                ><span style="color: #bbc0c7">Успех</span>
          </div>
          <div class="b-aclogo__descr"></div>
        </div>
      </router-link>
    </div>
    <div class="b-header__recall">

    </div>
    <div class="b-header__contacts">
      <router-link to="/about"> </router-link>
      <div class="b-accontacts">
        <div class="b-accontacts__phone">
          <a href="tel:89162771000">+7 (916) 277 10 00</a>
        </div>
        <div class="b-accontacts__adress">
          г. Москва ул. Газопровод д.2А<br>
          Координаты пункта технического осмотра:<br/>
          Широта: 55.591069  Долгота: 37.601738 <br>
          График работы:<br> ПОН.- ПЯТН.  9:00-20:00, СУБ. 9:00-18:00, ВОСК. 9:00-15:00  
        </div>
        <div class="b-accontacts__mail">
          Эл.почта:
          <a href="mailto:ooo.texuspex@gmail.com">ooo.texuspex@gmail.com</a>
        </div>
      </div>
      <br />
    </div>
  </header>
</template>

<script>
export default {
  name: "MyHeader"
}
</script>

<style scoped>
.b-header{
  display: table;
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 0 auto;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
</style>