<template>
  <div class="gmap hidden-xs">
    <div class="block-margin">
      <div class="bx-yandex-view-layout">
        <div class="bx-yandex-view-map gmap-pointer-parent">
          <div
              id="BX_GMAP_main"
              class="bx-google-map"
              style="height: 360px; width: 100%"
          >
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2254.7004077620195!2d37.601936315924206!3d55.5898229805101!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x414aad89c375cb6d%3A0x1cd764d8f55f67e9!2z0YPQuy4g0JPQsNC30L7Qv9GA0L7QstC-0LQsIDLQsCwg0JzQvtGB0LrQstCwLCAxMTc0MDU!5e0!3m2!1sru!2sru!4v1608889540289!5m2!1sru!2sru" width="100%" height="351" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

          </div>
          <div class="gmap-pointer hidden-xs">
            <div class="gmap-pointer__icon">
              <svg class="icon-svg svg-location">
                <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="#svg-location"
                ></use>
              </svg>
            </div>

            <div class="gmap-pointer__body">
              <strong
              >г. Москва ул. Газопровод д.2А</strong
              ><br/>
              График работы:<br/>
              ПОН.- ПЯТН.  9:00-20:00<br/> СУБ. 9:00-18:00<br/> ВОСК. 9:00-15:00 
              <br/>
              Телефон: 89162771000 <br/>
              <div>
                <a href="mailto:ooo.texuspex@gmail.com"
                >ooo.texuspex@gmail.com</a
                >
              </div>

              <b
              ><span style="color: black"
              ></span
              ></b
              ><br/>
              <br/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import GMap from "@/components/Ymap";

export default {

  name: "FooterMap",
  components: {},
  setup() {


    return {}
  }
}
</script>

<style scoped>
.gmap-pointer {
  position: absolute;
  top: 50px;
  left: 60%;
  width: 100%;
  max-width: 350px;
  padding: 28px 25px;
  margin: auto;
  margin-left: 15px;
  background-color: #4ba82e;
}
.gmap-pointer:after {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  content: ' ';
}

</style>