<template>
  <div class="banners_banner">
    <div class="banners_background">

    </div>
  </div>
</template>

<script>
export default {
  name: "Baner"
}
</script>

<style scoped>
.banners_banner {
  display: block;
  position: relative;
  width: 100%;
  height: 430px;
}

.banners_background {
  height: 431px;
  background-image: url('/img/tehuspeh.PNG');
  background-size: 100% 461px;
  background-repeat: no-repeat;
  display: inline-block;
  width: 100%;
}

.banners_background2 {
  height: 431px;
  background-image: url('/img/shema_viezda1.jpg');
  background-size: 100% 431px;
  background-repeat: no-repeat;
  display: inline-block;
  width: 50%;
}
</style>