<template>
  <Baner></Baner>
  <BarTu></BarTu>
  <div class="b-page">
    <div class="b-page__content">
      <h1 class="main-page"></h1>
      <div class="container block-margin-bottom">
        <div class="mini_banners">
          <div class="container block-margin">
            <div class="row">
              <div
                  class="mini_banner col-xs-6 col-sm-4 col-md-3 col-lg-4"
                  v-show="services[10].view"
              >
                <router-link
                    class="mini_banner__link clearfix"
                    to="/service/10"
                >
                  <div class="mini_banner__imgdiv">
                    <img
                        class="mini_banner__img"
                        src="/img//iblock/0de/0de83be3b9b9b45c2dd21512a6cd520c.jpg"
                        alt="Технический осмотр мототранспортные средств"
                        title="Технический осмотр мототранспортные средств"
                    />
                  </div>
                  <div class="mini_banner__info">
                      <span class="text-nowrap"
                      >{{ services[10].name }}</span
                      >
                  </div>
                </router-link>
              </div>
              <div
                  class="mini_banner col-xs-6 col-sm-4 col-md-3 col-lg-4"
                  v-show="services[0].view"
              >
                <router-link
                    class="mini_banner__link clearfix"
                    to="/service/0"
                >
                  <div class="mini_banner__imgdiv">
                    <img
                        class="mini_banner__img"
                        :src="services[0].img"
                        :alt="services[0].name"
                        :title="services[0].name"
                    />
                  </div>
                  <div class="mini_banner__info">
                      <span class="text-nowrap"
                      >{{ services[0].name }}</span
                      >
                  </div>
                </router-link>
              </div>
              <div
                  class="mini_banner col-xs-6 col-sm-4 col-md-3 col-4"
                  v-show="services[7].view"
              >
                <router-link
                    class="mini_banner__link clearfix"
                    to="/service/7"
                >
                  <div class="mini_banner__imgdiv">
                    <img
                        class="mini_banner__img"
                        :src="services[7].img"
                        :alt="services[7].name"
                        :title="services[7].name"
                    />
                  </div>
                  <div class="mini_banner__info">
                    <span class="text-nowrap">{{ services[7].name }}</span>
                  </div>
                </router-link>
              </div>
              <div
                  class="mini_banner col-xs-6 col-sm-4 col-md-3 col-lg-2-5"
                  v-show="services[5].view"
              >
                <router-link
                    class="mini_banner__link clearfix"
                    to="/service/5"
                >
                  <div class="mini_banner__imgdiv">
                    <img
                        class="mini_banner__img"
                        :src="services[5].img"
                        :alt="services[5].name"
                        :title="services[5].name"
                    />
                  </div>
                  <div class="mini_banner__info">
                      <span class="text-nowrap"
                      >{{ services[5].name }}</span
                      >
                  </div>
                </router-link>
              </div>
              <div
                  class="mini_banner col-xs-6 col-sm-4 col-md-3 col-lg-1-5"
                  v-show="services[1].view"
              >
                <router-link
                    class="mini_banner__link clearfix"
                    to="/service/1"
                >
                  <div class="mini_banner__imgdiv">
                    <img
                        class="mini_banner__img"
                        :src="services[1].img"
                        :alt="services[1].name"
                        :title="services[1].name"
                    />
                  </div>
                  <div class="mini_banner__info">
                    <span class="text-nowrap">{{ services[1].name }}</span>
                  </div>
                </router-link>
              </div>
              <div
                  class="mini_banner col-xs-6 col-sm-4 col-md-3 col-lg-2-5"
                  v-show="services[2].view"
              >
                <router-link
                    class="mini_banner__link clearfix"
                    to="/service/2"
                >
                  <div class="mini_banner__imgdiv">
                    <img
                        class="mini_banner__img"
                        :src="services[2].img"
                        :alt="services[2].name"
                        :title="services[2].name"
                    />
                  </div>
                  <div class="mini_banner__info">
                      <span class="text-nowrap"
                      >{{ services[2].name }}</span
                      >
                  </div>
                </router-link>
              </div>
              <div
                  class="mini_banner col-xs-6 col-sm-4 col-md-3 col-lg-4"
                  v-show="services[11].view"
              >
                <router-link
                    class="mini_banner__link clearfix"
                    to="/service/11"
                >
                  <div class="mini_banner__imgdiv">
                    <img
                        class="mini_banner__img"
                        :src="services[11].img"
                        :alt="services[11].name"
                        :title="services[11].name"
                    />
                  </div>
                  <div class="mini_banner__info">
                      <span class="text-nowrap"
                      >{{ services[11].name }}</span
                      >
                  </div>
                </router-link>
              </div>
            </div>

            <div class="mid-line text-center">
              <div class="mid-line__hr visible-lg"></div>
              <span class="mid-line__around"
              ><router-link class="btn btn-default" to="/services"
              >Посмотреть все услуги</router-link
              ></span
              >
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>





      <CarBrands></CarBrands>
      <FooterMap></FooterMap>
    </div>
  </div>
</template>

<script>
import Baner from "@/components/Baner";
import CarBrands from "@/components/CarBrands";
import BarTu from "@/components/BarTu";
import FooterMap from "@/components/FooterMap";
import myStore from "@/store/Store";
export default {
  name: "Index",
  components: {
    CarBrands,
    BarTu,
    FooterMap,
    Baner
  },
  setup(){
    const {services}=myStore()
    return {
      services
    }
  }

}
</script>

<style scoped>
.mini_banner__imgdiv{
width: 80%;
  margin-left: auto;
  margin-right: auto;
}
  .mini_banner__imgdiv img{
    width: 200px;
  }
</style>